<h3 [class]="'snackbar-' + data.type + '-header'">
  @switch (data.type) { @case ('success') {
  <mat-icon mat-list-icon class="snack-bar-icon-wrapper">
    <ng-icon class="snack-bar-icon" name="heroCheckCircle"></ng-icon>
  </mat-icon>
  } @case ('warning' || 'error') {
  <mat-icon mat-list-icon class="snack-bar-icon-wrapper .danger">
    <ng-icon
      class="snack-bar-icon"
      name="heroInformationCircle"
    ></ng-icon>
  </mat-icon>
  } @case('info') {
    <mat-icon mat-list-icon class="snack-bar-icon-wrapper .info">
    <ng-icon
      class="snack-bar-icon"
      name="heroInformationCircle"
    ></ng-icon>
  </mat-icon>
  }} 
</h3>
<p [class]="'snackbar-' + data.type + '-body'">{{ data.message }}</p>
<p class="snack-bar-action-text" (click)="closeSnackBar()" (keydown)="closeSnackBar()" tabindex="-1">Close</p>
