import { HttpParams } from '@angular/common/http';
import { ApiResponse } from '@root/models/global-interfaces';
import _, { isArray } from 'lodash';
import { Category } from 'src/models/category.model';

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/typedef */
export const createHttpParams = (data: any): HttpParams => {
  let queryParams = new HttpParams();
  const keys = Object.keys(data);
  for (const key of keys) {
    if (data[key] instanceof Array) {
      data[key].forEach((item) => {
        queryParams = queryParams.append(`${key.toString()}[]`, item);
      });
    } else if (data[key]){
      queryParams = queryParams.append(key, data[key])
    }
  }

  return queryParams;
};
/* eslint-disable @typescript-eslint/no-explicit-any */

// @TODO we have to get rid of manually chaning key names from snake case to camelCase when this is fixed from backend
type ObjectType = {
  [key: string]: any;
};
/* eslint-disable @typescript-eslint/no-explicit-any */

export function convertKeyToCamelCase(
  obj: ObjectType,
  key: string,
): ObjectType | any {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeyToCamelCase(item, key));
    /* eslint-disable @typescript-eslint/typedef */
  }

  const camelCaseObject: ObjectType = { ...obj };

  if (Object.hasOwn(obj, key)) {
    const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
      letter.toUpperCase(),
    );
    camelCaseObject[camelCaseKey] = obj[key];

    delete camelCaseObject[key];

    /* eslint-disable @typescript-eslint/typedef, @typescript-eslint/no-explicit-any */
  }

  return camelCaseObject;
}

export function findDescendants(array: any[], topic: any) {
  const descendants: any[] = [];

  const findChildren = (parentId: any) => {
    const children = array.filter((obj: any) => obj.topicParentID === parentId);

    for (const child of children) {
      /* eslint-disable @typescript-eslint/typedef */
      /* eslint-disable @typescript-eslint/no-explicit-any */
      descendants.push(child);
      findChildren(child.topicID);
    }
  };

  findChildren(topic.topicID);
  return descendants;
}

export function findAncestors(array: any[], currentChild: any) {
  const ancestors = [];
  while (currentChild && currentChild.topicParentID !== null) {
    const parent = array.find(
      (obj: any) => obj.topicID === currentChild.topicParentID,
    );
    if (parent) {
      parent.isExpanded = true;
      ancestors.unshift(parent);
      /* eslint-disable @typescript-eslint/no-explicit-any */
      currentChild = parent;
    } else {
      break;
    }
  }

  return ancestors;
}

export function searchCategoriesFunc(
  allCategories: Category[],
  /* eslint-disable @typescript-eslint/typedef */
  searchTerm: string,
) {
  if (!searchTerm) return allCategories;
  let tempArray: any[] = [];

  const searchItems =
    allCategories?.filter((item) =>
      item.topicTitle.toLowerCase().includes(searchTerm.toLowerCase()),
    ) ?? [];

  searchItems.forEach((element) => {
    tempArray.push(element);

    const ancestors = findAncestors(allCategories, element);

    /* eslint-disable @typescript-eslint/typedef */
    if (ancestors.length > 0) {
      tempArray = tempArray.concat(ancestors);
    }
    const descendants = findDescendants(allCategories, element);
    if (descendants.length > 0) {
      tempArray = tempArray.concat(descendants);
    }
  });

  tempArray = _.uniqBy(tempArray, function (topic) {
    return topic.topicID;

    /* eslint-disable @typescript-eslint/no-explicit-any */
  });

  return tempArray;
}

export function buildTree(array: any, parent = null) {
  const result: any[] = [];
  /* eslint-disable @typescript-eslint/typedef */
  for (const item of array) {
    if (item.topicParentID === parent || item.topicParentId === parent) {
      const children = buildTree(array, item.topicID);
      const newItem = {
        ...item,
        id: item.topicID,
        children: children.length > 0 ? children : [],
      };
      result.push(newItem);
    }
  }
  return result;
}

export function flattenResponse(res: ApiResponse, single?: boolean) {
  let dataRef = _.cloneDeep(res);
  const pagination: any = res.data.data ? _.cloneDeep(res.data) : {};

  while (dataRef?.data) {
    dataRef = dataRef.data;
  }

  delete pagination.data;

  let data = dataRef?.data ?? dataRef;
  data = single && isArray(data) ? data[0] : data;

  return {
    pagination,
    data,
  };
}
