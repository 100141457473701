import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import {
  ApiDataResponseArray,
  ApiDataResponseObject,
  ApiRequestParams,
  ApiResponse,
} from '@models/global-interfaces';
import { createHttpParams } from 'src/services/utilities';
import {
  CreateRoleParams,
  CreateRoleResponse,
  Cred,
  ProfileSummary,
  SingleProfileData,
  rolesTableApiRequestParams,
} from '@models/role.model';

// TODO: Backend change needed here. Need to be consistent with the profileCredID param name
export interface RoleProfileCred {
  profileCredID: number;
  profileCredStatus: number;
}

export interface RoleCreateProfileCred {
  credID: number;
  profileCredStatus: number;
}

export interface UpdateRolePayload {
  profileDescription: string,
  profileCreds: RoleProfileCred[],
}

export interface CreateRolePayload {
  profileDescription: string,
  credsArray: RoleCreateProfileCred[],
  locationID?: number
}

@Injectable()
export class RoleService {
  public apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getRoles(
    req: ApiRequestParams,
    locationID?: number,
  ): Observable<ApiDataResponseArray<ProfileSummary>> {
    if (locationID) {
      req.locationID = locationID;
    }
    /* eslint-disable @typescript-eslint/typedef */
    const params = req ?? rolesTableApiRequestParams;
    return this.http
      .get<ApiDataResponseObject<ApiDataResponseArray<ProfileSummary>>>(
        `${this.apiUrl}profiles`,
        {
          params: createHttpParams(params),
        },
      )
      .pipe(
        map(
          (
            data: ApiDataResponseObject<ApiDataResponseArray<ProfileSummary>>,
          ) => {
            return data as ApiDataResponseArray<ProfileSummary>;
          },
        ),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public getRolesByLocation(
    req: ApiRequestParams,
    locationID?: number,
  ): Observable<ProfileSummary[]> {
    if (locationID) {
      req.locationID = locationID;
    }
    /* eslint-disable @typescript-eslint/typedef */
    const params = req ?? rolesTableApiRequestParams;
    return this.http
      .get<ProfileSummary[]>(
        `${this.apiUrl}profiles`,
        {
          params: createHttpParams(params),
        },
      )
      .pipe(
        map(
          (
            data: ProfileSummary[],
          ) => {
            return data as ProfileSummary[];
          },
        ),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public getRoleById(
    profileId: number,
  ): Observable<SingleProfileData> {
    return this.http.get(`${this.apiUrl}profiles/${profileId}`).pipe(
      map((res: ApiResponse) => {
        return res.data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public createRole(
    params: CreateRoleParams,
  ): Observable<CreateRoleResponse> {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return this.http.post(`${this.apiUrl}profiles`, params).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public updateRole(
    profileId: number,
    payload: UpdateRolePayload,
  ): Observable<ApiResponse> {
    return this.http.put(`${this.apiUrl}profiles/${profileId}`, payload).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public deleteRole(profileID: number): Observable<ApiResponse> {
    return this.http.delete(`${this.apiUrl}profiles/${profileID}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public deleteLocalRole(profileID: number): Observable<ApiResponse> {
    return this.http.delete(`${this.apiUrl}securities/${profileID}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
        /* eslint-disable @typescript-eslint/no-explicit-any */
      }),
      finalize(() => {}),
    );
  };

  public assignRoleAndLocations(payload: any): Observable<any> {
    return this.http.post(`${this.apiUrl}securities`, payload).pipe(
      /* eslint-disable @typescript-eslint/no-explicit-any */
      map((res: any) => {
        return res.data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public unassignRole(securityID: number): Observable<any> {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return this.http.delete(`${this.apiUrl}securities/${securityID}`).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  /* eslint-disable @typescript-eslint/typedef */
  // TODO: We could probably do the same thing with default credentials that we are doing with
  // timezones, since this record set will not change often.
  public getCreds(): Observable<Cred[]> {
    return this.http.get(`${this.apiUrl}creds`).pipe(
      map((res: ApiDataResponseArray<Cred>) => {
        return res.data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public getTableRoles(
    req: ApiRequestParams,
  ): Observable<ApiDataResponseArray<ProfileSummary>> {
    const params = req ?? rolesTableApiRequestParams;
    return this.http
      .get<ProfileSummary[]>(`${this.apiUrl}profiles`, {
        params: createHttpParams(params),
      })
      .pipe(
        map((res: ProfileSummary[]) => {
          return { data: res };
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };
}
