import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-dialog-header',
  standalone: true,
  imports: [CommonModule, NgIconsModule],
  templateUrl: './dialog-header.component.html',
  styleUrl: './dialog-header.component.scss'
})
export class DialogHeaderComponent {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  @Input() title = '';
  @Input() dialogRef!: any;
  @Input() showBottomBorder: boolean = false;

  constructor() {}

  close() {
    this.dialogRef.close();
  }
}
