import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  NewTaskItemPayload,
  TaskItem,
  AddTaskFilesPayload,
  BaseTaskItem,
  DuplicateTaskItem,
  AddItemOption,
  UpdateItemOption,
  CreateReport,
} from '@models/task-item.model';
import { ApiResponse } from '@root/models/global-interfaces';
import { TaskItemOption } from '@root/models/task.model';

export const enum ItemTypes {
  CheckBox = 1,
  RadioList = 2,
  TextBox = 3,
  DropdownBox = 4,
  DatePicker = 5,
  Label = 7,
  Reassign = 8,
  FileAttachment = 9,
  AssignTask = 10,
  DeadlineDatePicker = 11,
  AppendToTitle = 13,
}

/* eslint-disable @typescript-eslint/typedef */
export const itemIcons = new Map([
  [ItemTypes.CheckBox, 'checkbox'],
  [ItemTypes.RadioList, 'heroListBullet'],
  [ItemTypes.TextBox, 'heroEllipsisHorizontalSolid'],
  [ItemTypes.DropdownBox, 'heroQueueList'],
  [ItemTypes.DatePicker, 'heroCalendarDays'],
  [ItemTypes.Label, 'heroTag'],
  [ItemTypes.Reassign, 'heroArrowsRightLeft'],
  [ItemTypes.FileAttachment, 'heroPaperClip'],
  [ItemTypes.AssignTask, 'heroArrowLongRightSolid'],
  [ItemTypes.DeadlineDatePicker, 'heroCalendar'],
  [ItemTypes.AppendToTitle, 'heroChevronDoubleRight'],
]);

export interface TaskItemOrderUpdate {
  taskItemID: number;
  order: number;
  parentItemOptionID: number;
  parentTaskItemID: number;
  itemID: number;
}
export interface TaskItemOptionOrderUpdate {
  optionID: number;
  order: number;
}

export interface TaskItemOrderBulkUpdate {
  taskItems: TaskItemOrderUpdate[];
  taskItemOptions: TaskItemOptionOrderUpdate[];
}
@Injectable()
export class TaskItemService {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public apiUrl = environment.apiUrl;
  store: any;

  constructor(private http: HttpClient) {}

  addTaskItem(payload: NewTaskItemPayload): Observable<TaskItem> {
    return this.http
      .post(`${this.apiUrl}task-item`, payload, {
        observe: 'response',
      })
      .pipe(
        /* eslint-disable @typescript-eslint/no-explicit-any */
        map((res: any) => {
          return res.body.data;
        }),
        catchError((error) => {
          /* eslint-disable @typescript-eslint/no-explicit-any */
          return throwError(() => error);
        }),
      );
  }
  updateTaskItem(payload: any): Observable<ApiResponse> {
    const { itemID, ...args } = payload;
    return this.http.put(`${this.apiUrl}task-item/${itemID}`, args).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  deleteTaskItem(taskItemID: number): Observable<TaskItem> {
    return (
      this.http
        /* eslint-disable @typescript-eslint/no-explicit-any */
        .delete(`${this.apiUrl}task-item/${taskItemID}`, {
          observe: 'response',
        })
        .pipe(
          map((res: any) => {
            return res.body.data;
          }),
          catchError((error) => {
            return throwError(() => error);
          }),
        )
    );
  }

  bulkUpdateTaskItemOrder(taskItemUpdate: TaskItemOrderBulkUpdate): Observable<void> {
    return this.http
      .put(`${this.apiUrl}task-item/order`, taskItemUpdate)
      .pipe(
        map(() => {
          return;
        }),
        catchError((error: string) => {
          return throwError(() => error);
        }),
      );
  }

  updateBulkTaskItems(
    taskItems: BaseTaskItem[],
    taskID: number,
  ): Observable<TaskItem> {
    return this.http
      .put(
        `${this.apiUrl}task-item/bulk-update/${taskID}`,

        {
          taskItems: taskItems,
        },
        {
          observe: 'response',
        },
      )

      .pipe(
        /* eslint-disable @typescript-eslint/no-explicit-any */
        map((res: any) => {
          return res.body.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
    /* eslint-disable @typescript-eslint/no-explicit-any */
  }

  addSchedule(reqObj: any): Observable<any> {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return this.http.post(`${this.apiUrl}schedules`, reqObj).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  updateSchedule(reqObj: any, taskReoccurID: number): Observable<any> {
    return this.http
      .put(`${this.apiUrl}task-reoccur/${taskReoccurID}`, reqObj)
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  deleteSchedule(taskReoccurID: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}task-reoccur/${taskReoccurID}`).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  duplicateTaskItem(
    reqObj: DuplicateTaskItem,
  ): Observable<TaskItem> {
    return this.http
      .patch(
        `${this.apiUrl}task-item-duplicate/${reqObj.itemID}`,
        {
          reqObj,
        },
        /* eslint-disable @typescript-eslint/no-explicit-any */
        {
          observe: 'response',
        },
      )
      .pipe(
        map((res: any) => {
          return res.body.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
    /* eslint-disable @typescript-eslint/no-explicit-any */
  }
  addOption(reqObj: AddItemOption): Observable<TaskItemOption> {
    return this.http
      .post(`${this.apiUrl}task-item-option`, reqObj, {
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res.body.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  deleteTaskItemOption(optionID: number): Observable<TaskItem> {
    return this.http
      .delete(`${this.apiUrl}task-item-option/${optionID}`, {
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res.body.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  updateOptionPrompt(
    reqObj: UpdateItemOption,
  ): Observable<TaskItem> {
    return (
      this.http
        .put(`${this.apiUrl}task-item-option/${reqObj.optionID}`, reqObj, {
          observe: 'response',
        })
        /* eslint-disable @typescript-eslint/no-explicit-any */
        .pipe(
          map((res: any) => {
            return res.body.data;
          }),
          catchError((error) => {
            return throwError(() => error);
          }),
        )
    );
  }

  createReport(reqObj: CreateReport): Observable<TaskItem> {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return this.http
      .post(`${this.apiUrl}task-item-report`, reqObj, {
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res.body.data;
        }),
        catchError((error) => {
          /* eslint-disable @typescript-eslint/no-explicit-any */
          return throwError(() => error);
        }),
      );
  }

  deleteTaskFile(fileID: number) {
    /* eslint-disable @typescript-eslint/typedef */
    return this.http.delete(`${this.apiUrl}task-files/${fileID}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      /* eslint-disable @typescript-eslint/no-explicit-any */
    );
  }

  getTaskFiles(itemID: number) {
    return this.http
      .get(`${this.apiUrl}task-files`, { params: { itemID } })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  addTaskFiles(req: AddTaskFilesPayload) {
    return this.http.post(`${this.apiUrl}task-files`, req).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  assignTask(taskItemId: number, taskId: number) {
    const req = {
      linkedTaskID: taskId,
    };
    return this.http
      .put(`${this.apiUrl}task-item/assign-link/${taskItemId}`, req)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }
}
