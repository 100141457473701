import {
  ApiRequestParams,
  ApiResponse,
  defaultApiRequestParams,
} from './global-interfaces';
import { RoleInfo, Security } from '@models/role.model';
import { AssignedLocation } from '@root/store/location.store';
import { Credentials } from './credentials.model';
import { ScopeType } from '@models/global-enum';
import { TaskItemType } from './task-item.model';

export const userTableApiRequestParams: ApiRequestParams = {
  ...defaultApiRequestParams,
  dataScope: ScopeType.UserTable,
  locationID: 0,
};

export interface LoginResponse extends ApiResponse {
  data?: {
    access_token: string;
    refresh_token: string;
  };
}

export interface RefreshTokenResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export interface GetUserByIdResponse extends ApiResponse {
  data: User & { roles: RoleInfo[] };
}

export interface CurrentUserResponse extends ApiResponse {
  data: CurrentUser;
}

export interface CreateUserResponse extends ApiResponse {
  customerID: number;
  userID: number;
  updated_at: Date;
  created_at: Date;
}

export interface LocationCred {
  locationID: number;
  credentialID: number;
  allowed: boolean;
}

export interface UserProfile {
  profileID: number;
  isLocalProfile: boolean;
  isGlobalProfile: boolean;
  profileParent: number;
  profileDescription: string;
  customerID: number;
  isAzureUser: boolean;
  notes: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  isLocationDefaultUser: number;
  userHash: string;
  userFullName: string;
}

export interface CurrentUser {
  allowedMaxFileUploadMb?: string;
  assignedLocations?: AssignedLocation[];
  globalCreds: {
    created_at: Date;
    deleted_at: Date;
    global_admin: boolean;
    global_viewer: boolean;
    id: number;
    report_admin: boolean;
    report_viewer: boolean;
    updated_at: Date;
    user_id: number;
  };
  isTaskBoardUser?: boolean;
  locationCreds: LocationCred[];
  userInfo?: UserInfo;
  profiles: UserProfile[];
  taskItemTypes: TaskItemType[];
  logoUrl: string;
  iconUrl: string;
}

export enum SSO_OPTIONS {
  OKTA = 'OKTA',
  AZURE = 'AZURE',
}

export interface CustomerInfo {
  ssoLogins: string[];
  logoUrl: string;
  iconUrl: string;
}

export interface TableUser {
  id: number;
  locationAndRole: string;
  userActive: boolean;
  userCredentials: Credentials[];
  userFullName: string;
  userID: number;
  isAzureUser: boolean;
  selected?: boolean;
  completeSelected?: boolean;
  userEmail: string;
}

// Database table: users
export interface User {
  // Database table columns
  userID: number;
  notifyEmailAssignmentGroup: number;
  notifyEmailAssignmentUser: number;
  notifyEmailOther: number;
  notifyEmailReminder: number;
  userActive: number;
  userApproved: number;
  userDefaultLocationID: null;
  userType: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userLoginAttempt: null;
  userLoginName: string;
  userPassword?: string;
  userPhone: null;
  userLogins: number;
  customerID: number;
  isAzureUser: number;
  notes: string | null;
  locationID: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  isLocationDefaultUser: number;
  userHash: string;

  // API response only (join)
  /* eslint-disable @typescript-eslint/no-explicit-any */
  securities: Security[];
  userFullName: string;
  customer: Customer;
  licenses?: any[];
  default_location: null;
  roles?: RoleInfo[];
  locationAndRole: string;
  userCredentials: string[];
}

export interface AssigneePreview {
  userFullName: string;
  userID: number;
  profileID: number;
  profileDescription: string;
}

export interface TaskUserPreview {
  userFirstName: string;
  userLastName: string;
  userFullName: string;
  userID: number;
}

// Database table: customers
export interface Customer {
  customerID: number;
  customerName: string;
  customerActive: number;
  created_at: Date;
  updated_at: Date;
  domain: string;
}

export interface UserInfo {
  userID: number;
  notifyEmailAssignmentGroup: number;
  notifyEmailAssignmentUser: number;
  notifyEmailOther: number;
  notifyEmailReminder: number;
  userActive: number;
  userApproved: number;
  userDefaultLocationID: number;
  userType: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userLoginAttempt: number;
  userLoginName: string;
  userPhone: string;
  userLogins: number;
  customerID: number;
  isAzureUser: boolean;
  notes: null | string;
  created_at: Date;
  updated_at: Date;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  isLocationDefaultUser: number;
  userHash: string;
  userFullName: string;
  customer: Customer;
  licenses: any[];
  default_location: number;
  logoUrl: string;
  profiles?: UserProfile;
}

export interface UserNotificationSettings {
  notifyEmailAssignmentUser: boolean;
  notifyEmailAssignmentGroup: boolean;
  notifyEmailReminder: boolean;
  notifyEmailOther: boolean;
}

export interface UserLoginParams {
  username: string;
  password: string;
}

export interface ChangePasswordParams {
  currentPassword: string;
  password: string;
}

export interface UpdateUserParams {
  userID: number;
  userFirstName?: string | null;
  userLastName?: string | null;
  userPhone?: string | null;
  userActive?: number;
  global_admin?: boolean;
  global_viewer?: boolean;
  report_admin?: boolean;
  report_viewer?: boolean;
}

export interface CreateUserParams {
  userFirstName: string;
  userLastName: string;
  userLoginName: string;
  userEmail: string;
}

export interface ResetPasswordParams {
  userID: number;
}

export interface UserMinimum {
  userID: number;
  userFullName: string;
}

export interface UserGlobalPermissions {
  created_at: Date;
  deleted_at: Date;
  global_admin: number;
  global_viewer: number;
  id: number;
  report_admin: number;
  report_viewer: number;
  updated_at: Date;
  user_id: number;
}

export interface UserWithPermissions {
  global_permissions: UserGlobalPermissions;
  roles: RoleInfo[];
  userActive: number;
  userEmail: string;
  userFirstName: string;
  userFullName: string;
  userID: number;
  userLastName: string;
  userLoginName: string;
  isAzureUser: boolean;
  userPhone: string;
  user_credentials: Credentials[];
  // This is used on the frontend only
  locationID: number;
}
