import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '@root/app/shared/components/dialog/dialog.component';
import { DialogParams } from 'src/models/global-interfaces';
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private defaultWidth = '500px';
  private defaultMaxHeight = '94vh';
  private defaultDisableClose = true;

  constructor(private dialog: MatDialog) {}

  open(params: DialogParams) {
    /* eslint-disable @typescript-eslint/typedef */
    const component = params.component || DialogComponent;
    const dialogRef = this.dialog.open(component, {
      width: params.width ?? this.defaultWidth,
      maxHeight: params.maxHeight ?? this.defaultMaxHeight,
      minHeight: params.minHeight ?? 'auto',
      disableClose: params.disableClose ?? this.defaultDisableClose,
      hasBackdrop: params.hasBackdrop ?? true,
      panelClass: params.customStyleClass ?? '',
      data: {
        title: params.title,
        content: params.content,
        okLabel: params.okLabel ?? 'Ok',
        cancelLabel: params.cancelLabel ?? 'Cancel',
        onConfirm: params.onConfirm ?? null,
        onCancel: params.onCancel ?? null,
        payload: params.payload,
        filterType: params.filterType,
        secondary: params.secondary,
        template: params.template ?? null,
        getItems: params.getItems ?? null,
        body: params.body ?? null,
        options: params.options ?? null,
        actions: params.actions ?? null,
        checkBox: params.checkBox ?? null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {

      if (result?.openTasksOption === true) {
        params.onConfirm(true);
      } else if (result?.openTasksOption === false) {
        params.onConfirm(false);
      } else {
        if (result) {
          if (params.onConfirm) {
            params.onConfirm(result);
          }
          if (params.closeDialog) {
            this.dialog.closeAll();
          }
        } else {
          if (params.onCancel) {
            params.onCancel();
          }
        }
      }
    });
    return dialogRef;
  }

  /* eslint-disable @typescript-eslint/typedef */

  openChild(dialog: MatDialog, params: DialogParams) {
    const component = params.component || DialogComponent;
    const dialogRef = dialog.open(component, {
      width: params.width ?? this.defaultWidth,
      maxHeight: params.maxHeight ?? this.defaultMaxHeight,
      minHeight: params.minHeight ?? 'auto',
      disableClose: params.disableClose ?? this.defaultDisableClose,
      hasBackdrop: params.hasBackdrop ?? true,
      data: {
        title: params.title,
        content: params.content,
        okLabel: params.okLabel ?? 'Ok',
        cancelLabel: params.cancelLabel ?? 'Cancel',
        onConfirm: params.onConfirm ?? null,
        onCancel: params.onCancel ?? null,
        payload: params.payload,
        secondary: params.secondary,
        template: params.template ?? null,
        body: params.body ?? null,
        actions: params.actions ?? null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (params.onConfirm) {
          params.onConfirm(true);
        }
      } else {
        if (params.onCancel) {
          params.onCancel();
        }
      }
      /* eslint-disable @typescript-eslint/no-explicit-any */
      /* eslint-disable @typescript-eslint/typedef */
    });
    return dialogRef;
  }

  confirmChanges(currentDialog: MatDialogRef<any>) {
    const dialogRef = this.open({
      title: `Unsaved Changes`,
      content: [
        {
          scope: 'danger',
          title: 'Warning',
          message: 'If you continue your unsaved changes will be lost.',
        },
        'You have unsaved changes, are you sure you want to continue?',
      ],
      closeDialog: false,
      onConfirm: () => currentDialog.close(),
      okLabel: 'Discard Changes',
      cancelLabel: 'Cancel',
    });

    return dialogRef;
  }
}
