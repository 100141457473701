<div class="login-wrapper">
  <div class="justify-center w-[525px]">
    <div class="container-box rounded-lg w-full bg-white flex justify-center">
      @if (showLogin) {
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="py-16">
          <div class="flex justify-center mb-8">
            @if(generalStore.logoUrl()) {
              <img class="logo" [ngSrc]="generalStore.logoUrl()" alt="Logo" height="32" width="218" priority/>
            }
          </div>
          
          @if (showResetPassMsg) {
            <div class="flex justify-center items-center mb-8">
              <ng-icon name="heroCheckCircleSolid"></ng-icon>
              <span class="text-center"
              >Password reset successfully. Please login with your new
              password.</span
              >
            </div>
          }
          <div *ngIf="isOffline" class="offline-message">
            *You're offline please check your network settings.
          </div>

          <div
            class="form-control-wrapper"
            [ngClass]="{ 'invalid-field': username?.errors && username?.dirty }"
          >
          <label for="username" class="body-medium-medium">Email or Username</label>
            <input
              matInput
              type="text"
              id="username"
              class="form-control"
              formControlName="username"
            />
            <app-input-error
              [condition]="username?.dirty && username?.errors?.['required']"
              errorMessage="This field is required"
            >
            </app-input-error>
          </div>
          <div
            class="form-control-wrapper"
            [ngClass]="{ 'invalid-field': password?.errors && password?.dirty }"
          >
            <label for="password" class="body-medium-medium">Password</label>
            <input
              matInput
              type="password"
              id="password"
              class="form-control"
              formControlName="password"
            />
            <app-input-error
              [condition]="password?.errors?.['required'] && password?.dirty"
              errorMessage="This field is required"
            >
            </app-input-error>
          </div>

          @if (errorMessage) {
            <div class="input-error">
              <app-input-error
                class="input-error mt-2"
                [errorMessage]="errorMessage"
              ></app-input-error>
            </div>
          }

          <div class="flex flex-col">
            <button class="default-login-button mb-3" [disabled]="loginForm.invalid">
              Sign In
            </button>
            <a
              class="forgot-pass hover:underline cursor-pointer float-left mt-1 body-medium-medium"
              (keydown)="handleKeyDown($event)"
              (click)="showLogin = false; loginForm.reset()"
              tabindex="0"
            >
              Forgot password?
            </a>

            <app-auth-buttons></app-auth-buttons>
          </div>
        </form>
      }

      @if (!showLogin) {
        <form [formGroup]="forgotPassword" class="py-16">
          <div class="flex justify-center mb-8">
            @if(generalStore.logoUrl()) {
              <img class="logo" [ngSrc]="generalStore.logoUrl()" alt="Logo" height="32" width="218" priority/>
            }
          </div>

          @if (!forgotPasswordMsg) {
            <div class="flex justify-center mb-3">
              <p class="body-medium-regular text-center">
                Enter your email or username to receive a password reset link.
              </p>
            </div>
          } @else {
            <div class="flex justify-center mb-4 remove-margin-top">
              <span class="text-center body-medium-regular leading-6"
                >If a matching email or username exists for {{forgotPasswordEmail.value}}.  you will receive an email with instructions. Please check your inbox to reset your password. </span
              >
            </div>
          }
          
          <div class="flex flex-col gap-6">
              @if(!hideInput) {
              <p class="label">Email or Username</p>
              <div
              class="form-control-wrapper no-mb"
              [ngClass]="{
                'invalid-field':
                forgotPasswordEmail?.errors && forgotPasswordEmail?.dirty
              }"
            >
            <input
            type="text"
            id="forgotPasswordEmail"
            class="form-control"
            formControlName="username"
            />
            <app-input-error
            [condition]="
                  forgotPasswordEmail?.errors?.['required'] &&
                  forgotPasswordEmail?.dirty
                  "
                errorMessage="This field is required"
                >
              </app-input-error>
            </div>
          }
            
            @if(!hideInput) {
              <div class="w-full h-12 rounded-md">
                <button
                class="default-login-button forgot-pass-button"
                [disabled]="forgotPassword.invalid"
                (click)="onSubmitForgetPassword()"
                >Send Link</button>
              </div>
            } @else {
              <p class="center-text body-medium-regular receive-email-text">Didn't receive a email? <span class="send-again" (click)="onSubmitForgetPassword()" (keydown)="onSubmitForgetPassword()" tabindex="-1" [class.disabled]="disableButton">Send Again</span></p>
            }
            <a class="back-login-text body-medium-medium" (click)="goBack(); forgotPassword.reset()" (keydown)=" goBack(); forgotPassword.reset()" tabindex="-1">Back To Login</a>
          </div>
        </form>
      }
    </div>
    <p class="lower-text body-small-regular">Confidential Unpublished Work of Authorship <br/>Copyright © Terlumina, LLC <br/>All Rights Reserved</p>
  </div>
</div>
