import { Component, effect, inject, isDevMode } from '@angular/core';
import { CustomerInfo } from '@root/models/user.model';
import { UserService } from '@root/services/user-service/user.service';
import { GeneralStore } from '@root/store/general.store';
import LogRocket from 'logrocket';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  readonly generalStore = inject(GeneralStore);
  readonly userService: UserService = inject(UserService);
  isLoaded: boolean = false;
  constructor() {
    if (!isDevMode()) {
      LogRocket.init('uzclgt/katanaprod');
    }

    effect(() => {
      this.isLoaded = this.generalStore.isLoaded();
    });

    this.userService.getCustomerInfo().subscribe((res: CustomerInfo) => {
      this.generalStore.setCustomerInfo(res);
    });
  }
  title = 'webapp';
}
