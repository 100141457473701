import { NgZone, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { JwtAuthService } from '@services/auth/jwt-auth.service';

/* eslint-disable @typescript-eslint/typedef */
const publicRoute = ['/login'];

/* eslint-disable @typescript-eslint/typedef */
export const loginGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const auth = inject(JwtAuthService);
  const ngZone = inject(NgZone);
  const isLoggedIn = auth.isLoggedin();

  if (isLoggedIn) {
    if (publicRoute.includes(state.url)) {
      ngZone.run(() => {
        /* eslint-disable @typescript-eslint/typedef */
        router.navigate(['/']);
      });
    }
    return true;
  } else {
    const url = state.url.split('?')[0];
    if (publicRoute.includes(url)) {
      return true;
    } else {
      ngZone.run(() => {
        router.navigate(['/login']);
      });
    }
    return false;
  }
};
