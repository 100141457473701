import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  ApiDataResponseArray,
  ApiDataResponseObject,
  ApiRequestParams,
} from '@models/global-interfaces';
import { createHttpParams } from 'src/services/utilities';
import { JwtAuthService } from '@root/services/auth/jwt-auth.service';
import {
  ChangePasswordParams,
  CreateUserParams,
  CreateUserResponse,
  CurrentUserResponse,
  CustomerInfo,
  LoginResponse,
  RefreshTokenResponse,
  ResetPasswordParams,
  TableUser,
  UpdateUserParams,
  UserLoginParams,
  UserWithPermissions,
  userTableApiRequestParams,
} from '@models/user.model';
import { LocalStoreService } from '../local-store.service';

@Injectable()
export class UserService {
  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private authService: JwtAuthService,
    private localStoreService: LocalStoreService
  ) {}

  public login(
    params: UserLoginParams,
  ): Observable<LoginResponse> {
    this.localStoreService.clear();
    return this.http.post(`${this.apiUrl}auth/login`, params).pipe(
      map((res: LoginResponse) => {
        if (res.data) {
          this.authService.setUserToken(res.data.access_token);
          this.authService.setRefreshToken(res.data.refresh_token);
        }
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public refreshToken(refreshToken: string): Observable<RefreshTokenResponse> {
    return this.http.post(`${this.apiUrl}auth/refresh-token`, {
      refreshToken: refreshToken
    }).pipe(
      tap((res: RefreshTokenResponse) => {
        this.authService.setUserToken(res.access_token);
        this.authService.setRefreshToken(res.refresh_token);
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    )
  }

  public getCurrentUser(): Observable<CurrentUserResponse> {
    return this.http.get(`${this.apiUrl}users/info`).pipe(
      map((res: CurrentUserResponse) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public getUserById(id: number, locationID: number): Observable<ApiDataResponseObject<UserWithPermissions>> {
    return this.http.get(`${this.apiUrl}users/${id}${locationID ? `?locationID=${locationID}` : ''}`).pipe(
      map((res: ApiDataResponseObject<UserWithPermissions>) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public getUsers (
    req: ApiRequestParams,
    locationID?: number,
  ): Observable<ApiDataResponseArray<TableUser>> {
    /* eslint-disable @typescript-eslint/typedef */
    if (locationID) {
      req.locationID = locationID;
    }
    const params = req ?? userTableApiRequestParams;
    return this.http
      .get<TableUser[]>(`${this.apiUrl}users`, {
        params: createHttpParams(params),
      })
      .pipe(
        map((res: TableUser[]) => {
          return { data: res };
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public getTableUsers(
    req: ApiRequestParams,
    locationID?: number,
  ): Observable<TableUser> {
    /* eslint-disable @typescript-eslint/typedef */
    if (locationID) {
      req.locationID = locationID;
    }
    const params = req ?? userTableApiRequestParams;
    return this.http
      .get<TableUser>(`${this.apiUrl}users`, {
        params: createHttpParams(params),
      })
      .pipe(
        map((res: TableUser) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public createUser (
    /* eslint-disable @typescript-eslint/no-explicit-any */
    params: CreateUserParams,
  ): Observable<CreateUserResponse> {
    return this.http.post(`${this.apiUrl}users`, { ...params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public updateUser (user: UpdateUserParams): Observable<any> {
    const { userFirstName, userLastName, userPhone, userActive,
      global_admin, global_viewer, report_admin, report_viewer
     } = user;

    return this.http
      .put(`${this.apiUrl}users/${user.userID}`, {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        userFirstName,
        userLastName,
        userPhone,
        userActive,
        global_admin,
        global_viewer,
        report_admin,
        report_viewer,
      })
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
        /* eslint-disable @typescript-eslint/no-explicit-any */
      );
  };

  public validateUsername (
    userLoginName: string,
  ): Observable<any> {
    return this.http

      .post(`${this.apiUrl}check-username`, { userLoginName })
      .pipe(
        /* eslint-disable @typescript-eslint/no-explicit-any */
        map((res: any) => {
          return res.data.usernameExists;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public validateEmail(userEmail: string): Observable<any> {
    return this.http.post(`${this.apiUrl}check-user-email`, { userEmail }).pipe(
      map((res: any) => {
        return res.data.userEmailExists;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public changePassword (
    /* eslint-disable @typescript-eslint/no-explicit-any */
    payload: ChangePasswordParams,
  ): Observable<any> {
    return this.http.post(`${this.apiUrl}users/change-password`, payload).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((error) => {
        return throwError(() => error);
        /* eslint-disable @typescript-eslint/no-explicit-any */
      }),
    );
  };

  public updateNotificationSettings (
    payload: any,
    /* eslint-disable @typescript-eslint/no-explicit-any */
  ): Observable<any> {
    return this.http
      .put(`${this.apiUrl}users/profile-settings`, { ...payload })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public resetPassword (
    /* eslint-disable @typescript-eslint/no-explicit-any */
    params: ResetPasswordParams,
  ): Observable<any> {
    return (
      this.http
        .put(`${this.apiUrl}users/reset-password/${params.userID}`, {})
        .pipe(
          map((data) => {
            return data;
          }),
          catchError((error) => {
            return throwError(() => error);
          }),
          finalize(() => {}),
        )
    );
    /* eslint-disable @typescript-eslint/no-explicit-any */
  };

  public getCustomerInfo (): Observable<CustomerInfo> {
    return this.http.get(`${this.apiUrl}customer`).pipe(
      map((res: ApiDataResponseObject<CustomerInfo>) => {
        return res.data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public forgotPassword (email: string): Observable<any> {
    return this.http.post(`${this.apiUrl}auth/forgot-password`, { email }).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public resetPasswordThroughToken (
    password: string,
    token: string,
  ): Observable<any> {
    return this.http
      .post(`${this.apiUrl}auth/reset-password`, { password, token })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };
}
