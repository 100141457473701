<app-dialog-header
  title="Notification Settings for {{ userName }}"
  [dialogRef]="dialogRef"
></app-dialog-header>
<mat-dialog-content>
  <div class="flex justify-end">
    <span class="top-email">Email</span>
  </div>
  <mat-list role="list">
    <mat-list-item role="listitem">
      <div class="flex justify-between all-notifications">
        <span>Toggle all notifications</span>
        <mat-slide-toggle
          (change)="changeSelectAll()"
          [ngModel]="masterEmailToggle"
          (ngModelChange)="masterEmailToggle = $event"
          class="mr-8"
          color="accent"
        >
        </mat-slide-toggle>
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span>
          Send me a notification when a task is assigned to me specifically
        </span>

        @if (userNotificationSettings) {
        <mat-slide-toggle
          [ngModel]="userNotificationSettings.notifyEmailAssignmentUser"
          (ngModelChange)="userNotificationSettings.notifyEmailAssignmentUser = $event"
          (change)="commitChange()"
          class="mr-8"
          color="accent"
        ></mat-slide-toggle>
        }
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span
          >Send me a notification when a task is assigned to my group/role</span
        >

        @if (userNotificationSettings) {
        <mat-slide-toggle
          [ngModel]="userNotificationSettings.notifyEmailAssignmentGroup"
          (ngModelChange)="userNotificationSettings.notifyEmailAssignmentGroup = $event"
          (change)="commitChange()"
          class="mr-8"
          color="accent"
        ></mat-slide-toggle>
        }
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span class="notification-title">
          Send me automatic task reminders
          <ng-icon
            name="heroQuestionMarkCircleSolid"
            class="tooltip"
            #tooltip="matTooltip"
            [matTooltipPosition]="tooltipPosition"
            matTooltip="Automatic task reminders are set by managers and administrators before the task spawns, and the message is generic. If you think a particular task is sending too many or not enough reminders, ask someone with task editing capabilities to adjust the reminder settings for that task."
          ></ng-icon>
        </span>

        @if (userNotificationSettings) {
        <mat-slide-toggle
          [ngModel]="userNotificationSettings.notifyEmailReminder"
          (ngModelChange)="userNotificationSettings.notifyEmailReminder = $event"
          (change)="commitChange()"
          class="mr-8"
          color="accent"
        ></mat-slide-toggle>
        }
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span class="notification-title">
          Send me manual task reminders
          <ng-icon
            name="heroQuestionMarkCircleSolid"
            class="tooltip"
            #tooltip="matTooltip"
            [matTooltipPosition]="tooltipPosition"
            matTooltip="Manual reminders are sent purposefully and individually after the task spawns. These cannot be turned off. If you are receiving too many of these notifications, ask the users who are sending them to stop."
          ></ng-icon>
        </span>
        <mat-slide-toggle
          [disabled]="true"
          [checked]="true"
          class="mr-8"
          color="accent"
        >
        </mat-slide-toggle>
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span class="notification-title">
          Send me notifications regarding my account
          <ng-icon
            name="heroQuestionMarkCircleSolid"
            class="tooltip"
            #tooltip="matTooltip"
            [matTooltipPosition]="tooltipPosition"
            matTooltip="This includes security alerts, notifications regarding your password or other credentials, and messages from Katana employees. These notifications cannot be turned off."
          ></ng-icon>
        </span>
        <mat-slide-toggle
          [disabled]="true"
          [checked]="true"
          class="mr-8"
          color="accent"
        >
        </mat-slide-toggle>
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span class="notification-title">
          Send me other notifications
          <ng-icon
            name="heroQuestionMarkCircleSolid"
            class="tooltip"
            #tooltip="matTooltip"
            [matTooltipPosition]="tooltipPosition"
            matTooltip="This includes notifications which do not belong in any of the above categories."
          ></ng-icon>
        </span>

        @if (userNotificationSettings) {
        <mat-slide-toggle
          [ngModel]="userNotificationSettings.notifyEmailOther"
          (ngModelChange)="userNotificationSettings.notifyEmailOther = $event"
          (change)="commitChange()"
          class="mr-8"
          color="accent"
        ></mat-slide-toggle>
        }
      </div>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="cancel-button" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
