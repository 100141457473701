<mat-toolbar>
  <div class="flex all-wrapper">
    @if (showLogo) {
      <div class="flex">
        @if(generalStore.logoUrl()){
          <img class="logo" [ngSrc]="generalStore.logoUrl()" alt="Logo" height="23" width="157" />
        }
      </div>
    }
    <div class="profile-menu-container">
      <div class="titles">
        <p class="username text-sm">
          {{ userFullName }}
        </p>
        <p class="desc text-xs">
          {{ customerName }}
        </p>
      </div>
  
      <div [matMenuTriggerFor]="menu" class="menu-trigger">
        <button class="avatar icon">
          <ng-icon name="heroUserCircleSolid"></ng-icon>
        </button>
        <div>
          <mat-menu
            #menu="matMenu"
            class="profile-menu p-2.5 absolute top-6 right-1 mt-[-6px] w-[240px] rounded-xl"
          >
            <button
              class="profile-menu-item rounded-lg p-2.5 w-full"
              (click)="openChangePasswordModal()"
            >
              <span class="flex items-center">
                <ng-icon
                  class="text-xl pr-2.5"
                  name="heroLockClosedSolid"
                ></ng-icon>
                <span class="block pl-2.5 text-sm">Change Password</span>
              </span>
            </button>
            <button
              class="profile-menu-item rounded-lg p-2.5 w-full"
              (click)="openNotificationSettingsModal()"
            >
              <span class="flex items-center">
                <ng-icon class="text-xl" name="heroBellSolid"></ng-icon>
                <span class="block pl-2.5 text-sm">Notification Settings</span>
              </span>
            </button>
            <mat-divider></mat-divider>
            <button
              class="profile-menu-item rounded-lg p-2.5 w-full"
              (click)="logout()"
            >
              <span class="flex items-center">
                <ng-icon
                  class="text-xl pr-2.5"
                  name="heroArrowRightOnRectangle"
                ></ng-icon>
                <span class="block pl-2.5 text-sm">Logout</span>
              </span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
