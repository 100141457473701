import { ComponentType } from '@angular/cdk/portal';
import { SortDirection } from '@angular/material/sort';
import { ScopeType } from './global-enum';
import {
  CategoryPreviewApiResponse,
  categoryOptions,
  CategoryPreview,
} from './category.model';
import { Observable } from 'rxjs';

export interface ApiResponse {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  data?: any | any[];
  message?: string;
  status?: string;
}

export interface TypedApiResponse<T> {
  data?: T | T[];
}

export interface ApiDataResponseArray<T> {
  data?: T[];
  currentPage?: number;
  lastPage?: number;
  perPage?: number;
  sortBy?: string;
  sortOrder?: SortDirection;
  total?: number;
}

export interface ApiDataResponseMessage {
  data?: string;
}

export interface ApiDataResponseObject<T> {
  data?: T;
  currentPage?: number;
  lastPage?: number;
  perPage?: number;
  sortBy?: string;
  sortOrder?: string;
  total?: number;
}

export interface ApiResponseArray<T> {
  data?: ApiDataResponseArray<T>;
  message?: string;
  status?: string;
}

export interface ApiResponseMessage {
  data?: ApiDataResponseMessage;
  message?: string;
  status?: string;
}

export interface ApiRequestParams {
  scope?: ScopeType;
  globalTasks?: number;
  dataScope?: ScopeType;
  locationID?: number;
  nativeTasks?: number;
  deployed?: number;
  dueStartDate?: string;
  dueEndDate?: string;
  completedEndDate?: string;
  completedStartDate?: string;
  categoryID?: number;
  locations?: number[];
}

export const defaultApiRequestParams: ApiRequestParams = {

};

export interface DashboardGraphs {
  locationID?: number;
  categoryID?: number;
  completionStatus?: string;
}

export interface DialogParams {
  getItems?: (req: ApiRequestParams) => Observable<CategoryPreview[]>;
  options?: categoryOptions;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  filterType?: string;
  content?: any[] | string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  body?: any;
  actions?: any;
  confirmUnsavedChanges?: any;
  cancelLabel?: string | boolean;
  closeDialog?: boolean;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  component?: ComponentType<any>;
  disableClose?: boolean;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  maxHeight?: string;
  messages?: string[];
  minHeight?: string;
  okLabel?: string | boolean;
  payload?: any;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  locationID?: number;
  topics?: CategoryPreviewApiResponse[];
  secondary?: any;
  storeAction?: any;
  title?: string;
  width?: string;
  type?: boolean;
  height?: string;
  checkBox?: string;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  hasBackdrop?: boolean;
  onConfirm?: any;
  onCancel?: any;
  template?: {
    body?: DialogTemplateParams;

    actions?: DialogTemplateParams;
  };
  customStyleClass?: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface DialogTemplateParams {
  component: ComponentType<any>;
  injector: any;
  inputs?: { [key: string]: any };
  content?: any[];

  /* eslint-disable @typescript-eslint/no-explicit-any */
  providers?: any[];
  deps?: any[];
}

export interface EventTaskInfo {
  summary?: string;
  dueAt?: string;
  taskID?: number;
  id?: number;
  locationID?: string | number;
  profileID?: number;
  type?: string;
  userID?: any;
  taskSetID?: number;
  data?: EventTaskInfoData;
}

export interface TaskboardInfo {
  openTasks: TaskboardTask[];
  closedTasks: TaskboardTask[];
}

export interface TaskboardTask {
  taskName: string;
  category: CategoryPreviewApiResponse;
  dueAt: string;
  assignedTo: assignedToTask;
  color: number
}

export interface assignedToTask {
  profileDescription: string;
  userFullName: string;
}
export interface EventTaskInfoData {
  locationID: number;
  taskID?: number;
  taskSetID?: number;
  task_set_name?: string;
  summary?: string;
  dueAt?: string;
  userID?: number;
  id?: number;
  profileID?: number;
  type?: string;
  name?: string;
  /* eslint-disable @typescript-eslint/typedef */
  profileDescription?: string;
  topicTitle?: string;
}

export interface Link {
  url: null | string;
  label: string;
  active: boolean;
}

export interface AutocompleteInputObject {
  name: string;
  id: number;
}

export const StatusIcons = new Map([
  ['success', 'heroCheckCircleSolid'],
  ['warning', 'heroExclamationTriangleSolid'],
  ['danger', 'heroExclamationTriangleSolid'],
  ['info', 'heroInfoCircleSolid'],
]);

export interface ScoreTaskResonse {
  score: number;
  task_observation: string;
  task_recommendation: string;
  id: number;
}
