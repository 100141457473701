import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { QuillModule } from 'ngx-quill';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from '../interceptors/http.interceptor';
import { MatTableModule } from '@angular/material/table';
import { LoginComponent } from '@views/auth/login/login.component';
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';
import { ErrorInterceptor } from '../interceptors/error.interceptor';
import { NgIconsModule } from '@ng-icons/core';
import { HeaderComponent } from '@shared/components/header/header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { DocumentService } from '@root/services/document-service/document.service';
import { CategoryService } from '@root/services/category-service/category.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TaskService } from '@root/services/task-service/tasks.service';
import { TaskSetService } from '@root/services/task-set-service/task-set.service';

import { Icons } from './icons';
import { RoleService } from '@root/store/role.service';
import { CredentialsService } from '@root/services/credentials.service';
import { TaskItemService } from '@root/services/task-service/task-item.service';
import { LocationService } from '@root/services/location.service';
import { UserService } from '@root/services/user-service/user.service';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

@NgModule({
  declarations: [AppComponent],
  providers: [
    UserService,
    LocationService,
    DocumentService,
    CategoryService,
    TaskService,
    TaskSetService,
    TaskItemService,
    RoleService,
    CredentialsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MatPaginatorIntl,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    MatTableModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    NgIconsModule.withIcons(Icons),
    LoginComponent,
    SidebarComponent,
    HeaderComponent,
    MatDialogModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    QuillModule.forRoot()
  ],
})
export class AppModule {}
