import { Category } from '@models/category.model';
import { ScopeType, TaskScope } from './global-enum';
import {
  ApiRequestParams,
  ApiResponse,
  defaultApiRequestParams,
} from './global-interfaces';
import { AssigneePreview, TaskUserPreview, User } from '@models/user.model';
import { Profile, ProfileCred, ProfileSummary } from '@models/role.model';
import { AssignableTask, ClosedTaskResponse, TaskSetTask } from '@models/task.model';
import { LocationDetails, LocationPreview } from '@root/store/location.store';
import { TableTag } from './document.model';

export const taskSetTableApiRequestParams: ApiRequestParams = {
  ...defaultApiRequestParams,
  dataScope: ScopeType.TaskSetTable,
};

export interface TaskSetResponse<T> extends ApiResponse {
  data: T;
}

export interface TaskSetMinimum {
  id: number;
  task_set_name?: string | null;
}

export interface TaskSetBase {
  id: number;
  name_addition?: string | null;
  task_set_name?: string;
}

export interface TaskSetInfo extends TaskSetBase {
  topic?: Category;
  default_assignee?: User | ProfileCred | null;
  created_at?: string;
  completed_at?: string;
  created_by?: string;
  private_notes?: string;
  public_notes?: string;
  is_completed?: boolean;
  is_deployed?: boolean;
  is_spawned?: boolean;
  summary?: string;
  color: number;
}

export interface EditTaskSet extends TaskSetBase {
  roles: Profile[];
  topics: Category[];
  topic: Category;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  tags: TableTag[];
  taskSet: any;
  tasks: TaskSetTask[];
  profile: Profile;
  availableTags: TableTag[];
  availableTasks: AssignableTask[]
}

export interface TaskSetOptions extends TaskSetBase {
  deployed_locations?: DeployedLocation[] | undefined;
  locations?: LocationPreview[];
  topics?: Category[];
  topic_id?: number;
  task_set_id: number;
  duration: string;
  turn_green: number;
  turn_yellow: number;
  turn_red: number;
  notify_on_assignment: number;
  notify_on_yellow: number;
  notify_on_red: number;
  notify_on_completion: number;
  task_reoccurs?: TaskSetReoccurs[];
  deploy_status: boolean;
  spawn_status: boolean;
  message?: string;
  allow_events?: number;
  allow_assessment?: number;
  is_deployed?: boolean;
  timezone_abbreviation?: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */

  default_assignee: any;
  due_at: string;
  due_date: string;
  location_id: number;
  roles: ProfileSummary[];
  users: TaskUserPreview[];
}

export interface DeployedLocation {
  taskID: number;
  name?: string;
  type?: number;
  deleted?: number;
  userID?: null;
  profileID?: null;
  assignMulti?: number;
  topicID?: number;
  locationID?: number;
  templateID?: number;
  createdDate?: null;
  dueDate?: null;
  completedDate?: null;
  color?: number;
  userCompleted?: null;
  turnGreen?: number;
  turnYellow?: number;
  turnRed?: number;
  batchID?: number;
  builderComments?: string;
  locationName?: string;
  customerID?: number;
  approvalStatus?: null;
  summary?: string;
  purpose?: string;
  notifyOnAssignment?: number;
  notifyOnYellow?: number;
  notifyOnRed?: number;
  nameAddition?: string;
  isTaskSet?: number;
  taskSetTemplateID?: number;
  shortName?: null;
  priority?: number;
  isAssociatedTaskCompleted?: null;
  associatedTaskCompletedUser?: null;
  notifyOnTaskSetCompletion?: number;
  associatedTaskCompletedDate?: null;
  dashboardID?: null;
  isDeprecated?: number;
  typeID?: number;
  dueAt?: null;
  completedAt?: null;
  associatedTaskCompletedAt?: null;
  tempBatchID?: null;
  created_at?: string;
  updated_at?: string;
  deleted_at?: null;
  isTaskItemTask?: number;
  createdBy?: number;
  isLocalAddedTask?: number;
  isUpdatedColor?: number;
  isSpawned?: boolean;
  isCompleted?: boolean;
  isNative?: boolean;
  isGlobal?: boolean;
  isDeployed?: boolean;
  isOpen?: boolean;
  dueDateDisplay?: null;
  hasScheduled?: boolean;
  dueAtStatusDisplay?: string;
  finalColor?: number;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  hasAppendToTitleTaskItem?: boolean;
  location: LocationDetails;
  task_schedules?: any[];
  delays?: any[];
}

export interface TaskSetReoccurs {
  name?: string;
  reoccurID?: number;
  type?: number;
  startAt?: string;
  field1?: number;
  field2?: string;
  field3?: string;
  field4?: string;
  dueAt?: string;
  reoccurAt?: string;
  failed_tasks_schedule?: {
    id?: number;
    reoccur_id?: number;
    duration?: number;
    new_tasks?: number;
    time_interval?: string;
  };
}

export interface NewTaskSetTemplates extends ApiResponse {
  data: TaskSetBase;
}

export interface DuplicateTaskSetRequest {
  task_set_name: string;
  location_id?: number;
  id: number;
}
export interface DeployedLocations extends ApiResponse {
  data: TaskSetBase[];
}

export interface SpawnTaskSetRequest {
  assignmentType?: string;
  locationID: number;
  profileID?: number;
  taskSetID: number;
  itemID?: number;
  timestamp: string;
  userID?: number;
  timeIntervalID?: number;
  timezone_abbreviation?: string;
}

export interface TaskSetTableRecord {
  created_at: string;
  task_set_name: string;
  id: number;
  topic: Category;
  name_addition: string;
  task_set_status: string;
  tasks: TaskSetTask[];
  default_assignee: AssigneePreview;
  assignedBy: string;
}

export interface CreateTaskSetRequest {
  location_id?: number;
}

export class TaskSetRequest {
  public id: number;
  public scope: TaskScope;
  public constructor(id: number, scope?: TaskScope) {
    this.id = id;
    if (scope) {
      this.scope = scope;
    }
  }
}

export interface taskSetNameAdditionPayload {
  id: number;
  name_addition: string;
}

export interface taskSetNotificationsPayload {
  id: number;
  notify_on_red: number;
  notify_on_yellow: number;
  notify_on_assignment: number;
  notify_on_completion: number;
}
export interface RecallDeploymentTaskSetPayload {
  id: number;
  locationID: number;
  openTaskSetsOption: boolean;
}

export interface TaskSetDeploymentPayload {
  assignmentOption: number;
  locationIDS: number[];
  notificationOption: number;
  openTasksOption: number;
  scheduleOption: boolean;
  id?: number;
}

export interface ClosedTaskSetResponse {
  id: number;
  nameAddition: string;
  taskSetName: string;
  completedAt: string;
  failedAt: string;
  failedBy: string;
  dueAt: string;
  topic: Category;
  tasks: ClosedTaskResponse[];
  closedTaskSets: ClosedTaskResponse[];
  timezone_abbreviation: string;
}
