import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-new-action-button',
  templateUrl: './new-action-button.component.html',
  styleUrls: ['./new-action-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
})
export class NewActionButtonComponent {
  @Input() icon = '';
  @Input() text = '';
  @Input() themedButton: string = '';
  @Input() showLoader = false;
  @Input() isDisabled = false;
  @Input() fullWidth = false;
  @Output() actionCompleted = new EventEmitter<void>();
  inProgress = false;
  destroyRef = inject(DestroyRef);

  clickAction() {
    // TODO: not sure what this component is supposed to do, but need to make sure these actions are happening still
    // this.isInProgress$
    //   ?.pipe(takeUntilDestroyed(this.destroyRef))
    //   .subscribe((progress) => {
    //     this.inProgress = progress;
    //   });
    this.actionCompleted.emit();
  }
}
