<button
  class="new-action-btn"
  mat-raised-button
  (click)="clickAction()"
  [disabled]="inProgress || isDisabled"
  [class.disabled]="inProgress || isDisabled"
  [ngClass]="{
    'success-button': !themedButton,
    'primary-button': themedButton === 'primary',
    'secondary-button': themedButton === 'secondary',
    spinner: inProgress && showLoader,
    'w-full': fullWidth
  }"
>
  @if (icon) {
    <mat-icon>{{ icon }}</mat-icon>
  }
  {{ text }}
</button>
