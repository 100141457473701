import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ApiResponse } from '../models/global-interfaces';
import { Credentials } from '@root/models/credentials.model';

@Injectable()
export class CredentialsService {
  public apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public readonly createCredentials = (
    newCredentials: Credentials,
  ): Observable<ApiResponse> => {
    return this.http
      .post(`${this.apiUrl}user-credentials`, newCredentials)
      .pipe(
        /* eslint-disable @typescript-eslint/no-explicit-any */
        map((res: any) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public readonly updateCredentials = (
    updatedCredentials: Credentials,
  ): Observable<ApiResponse> => {
    return this.http
      .put(
        `${this.apiUrl}user-credentials/${updatedCredentials.id}`,
        updatedCredentials,
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public readonly deleteCredentials = (
    credentialsId: number,
  ): Observable<ApiResponse> => {
    return this.http
      .delete(`${this.apiUrl}user-credentials/${credentialsId}`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };
}
