import { NgIf } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { GeneralStore } from '@root/store/general.store';
import { environment } from 'src/environments/environment';
import { SSO_OPTIONS } from 'src/models/user.model';

@Component({
  selector: 'app-auth-buttons',
  templateUrl: './auth-buttons.component.html',
  styleUrls: ['./auth-buttons.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class AuthButtonsComponent {
  public loaded: boolean;
  public disableSSO: boolean = false;
  public inProgressAzure: boolean = false;
  public inProgressOkta: boolean = false;
  readonly generalStore = inject(GeneralStore);
  public showOktaLogin: boolean = false;
  public showAzureLogin: boolean = false;

  constructor() {
    effect(() => {
      const ssoLogins: string[] = this.generalStore.ssoLogins();
      if (ssoLogins) {
        ssoLogins.forEach((login) => {
          if (login === SSO_OPTIONS.OKTA) this.showOktaLogin = true;
          if (login === SSO_OPTIONS.AZURE) this.showAzureLogin = true;
        });
        this.loaded = true;
      }
    });
  }

  public readonly loginWithSSO = (provider: string): void => {
    this.disableSSO = true;
    if (provider == 'azure') this.inProgressAzure = true;
    else if (provider == 'okta') this.inProgressOkta = true;
    window.location.href = `${environment.apiUrl}auth/${provider}/redirect`;
  };
}
