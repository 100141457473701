import { Component, effect, inject } from '@angular/core';
import { GeneralStore } from '@root/store/general.store';
import { HeaderComponent } from '../../header/header.component';
import { SidebarComponent } from '../../sidebar/sidebar.component';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
  standalone: true,
  imports: [HeaderComponent, SidebarComponent],
})
export class DashboardLayoutComponent {
  private _sidebarExpanded: boolean = true;
  readonly generalStore = inject(GeneralStore);

  constructor() {
    effect(() => {
      this._sidebarExpanded = this.generalStore.sidebarExpanded();
    });
  }

  handleExpandSidebar(expand: boolean) {
    this.generalStore.setSidebarExpanded(expand);
  }
}
