export enum GlobalPermissions {
  GLOBAL_ADMIN,
  GLOBAL_VIEWER,
  REPORT_ADMIN,
  REPORT_VIEWER
}

export enum LocalPermissions {
  LOCAL_ADMIN = 1,
  LOCAL_VIEWER = 2,
  SPAWN_FROM_EVENT_WIZARD = 3,
  ALLOW_TASK_SCORING = 4,
  COMPLETE_OTHERS_TASKS = 5,
  DELETE_SPAWNED_TASKS = 6,
  ASSIGN_TASK_AND_SETS_TO_OTHERS = 7,
  REASSIGN_MY_TASKS_AND_TASK_SETS_TO_OTHERS = 8,
}

export enum ScopeType {
  GlobalScope = 'global',
  LocalScope = 'local',
  TaskScope = 'task',
  UserTable = 'userTable',
  RoleTable = 'roleTable',
  LocationTable = 'locationTable',
  TaskTable = 'taskTable',
  EditTask = 'editTask',
  TaskSetTable = 'taskSetTable',
  LocalUserTable = 'localUserTable',
  LocalTaskTable = 'localTaskTable',
  DeployedTaskTable = 'globalDeployed',
  LocalTaskSetTable = 'localTaskSetTable',
  Deployed = 'deployed',
  MyTasks = 'myTasks',
  MyTaskSets = 'myTaskSets',
  EventTask = 'eventTask',
  Completed = 'completed',
}

export enum TaskScope {
  LOCAL = 'local',
  GLOBAL = 'global',
  DEPLOYED = 'deployed',
  EVENT = 'event',
  COMPLETED = 'completed',
  FAILED = 'failed',
  SPAWNED = 'spawned',
}

export enum RowActions {
  DELETE = 'delete',
  EDIT = 'edit',
  UNLINK = 'unlink',
  SPAWN = 'spawn',
  DEFAULT_SPAWN = 'defaultSpawn',
  MESSAGE = 'message',
}

export enum DefaultColorValue {
  red = 3,
  yellow = 2,
  green = 1,
  none = 0,
}
