import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, share, take, takeUntil } from 'rxjs/operators';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  ApiDataResponseArray,
  ApiRequestParams,
  ApiResponse,
  defaultApiRequestParams
} from '@models/global-interfaces';
import { createHttpParams } from 'src/services/utilities';
import {
  AddDocumentParams,
  AddDocumentResponse,
  CreateLinkParams,
  DeleteDocument,
  EditLawParams,
  FileProgress,
  FileUploadParams,
  PresignUrlResponse,
  TableDocument,
  TableReference
} from '@models/document.model';
import { NotificationService } from 'src/services/notification.service';
import { TaskService } from '../task-service/tasks.service';
import { User } from '@root/models/user.model';

export class DocumentUploadEvent {
  documentID: number;
  customerID: number;
  locationID: number;
  documentFileName: string;
  documentScope: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  uploaded_by: number;
  uploadFileName: string;
  uploaded_by_user: User;
}

@Injectable()
export class DocumentService {
  public apiUrl = environment.apiUrl;
  public uploadedFileName = '';

  public _dataStream: ReplaySubject<Array<FileProgress>> = new ReplaySubject();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public readonly abortSubjects: Map<File, Subject<any>> = new Map();

  public readonly fileProgressList: Array<FileProgress> = [];

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public readonly uploadedFiles: Array<any> = [];

  public readonly uploadedFilesSubject = new Subject<any>();

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private taskService: TaskService,
  ) {}

  getPreSignUrl = (fileName: string): Observable<PresignUrlResponse> => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return this.http
      .get(`${this.apiUrl}presignedurl?fileName=${fileName}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
      );
  };
  /* eslint-disable @typescript-eslint/no-explicit-any */

  public readonly uploadDocument = (
    url: string,
    file: File,
  ): Observable<any> => {

    return this.http
      .put(url, file, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': file.type,
        },
        /* eslint-disable @typescript-eslint/no-explicit-any */
        /* eslint-disable @typescript-eslint/typedef */
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        share(),

        map((event: HttpEvent<any>) => {
          let progress;
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.DownloadProgress:
              break;
            case HttpEventType.UploadProgress:
              if (event.total)
                progress = Math.round((event.loaded / event.total) * 100);
              return {
                type: 'PROGRESS',
                progress,
              };

            case HttpEventType.Response:
              return {
                type: 'COMPLETE',
                status: true,
                body: event.body,
              };
            default:
              break;
          }
          return event;
        }),
      );
  };

  //for global tasks only
  public readonly getDocuments = (
    req: ApiRequestParams,
  ): Observable<ApiDataResponseArray<TableDocument>> => {
    return this.http
      .get<TableDocument[]>(`${this.apiUrl}document`, {
        params: createHttpParams(req),
      })
      .pipe(
        map((res: TableDocument[]) => {
          return { data: res };
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public readonly createDocument = (
    data: AddDocumentParams,
  ): Observable<AddDocumentResponse> => {
    return this.http.post(`${this.apiUrl}document`, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public readonly deleteDocument = (
    documentID: number,
  ): Observable<DeleteDocument> => {
    return this.http.delete(`${this.apiUrl}document/${documentID}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public readonly getDocumentById = (
    documentID: number,
  ): Observable<ApiResponse> => {
    return this.http.get(`${this.apiUrl}document/${documentID}`).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public readonly getDocumentByFileId = (
    fileID: number,
  ): Observable<ApiResponse> => {
    return this.http.get(`${this.apiUrl}task-files/${fileID}`).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public get dataStream(): Observable<Array<FileProgress>> {
    return this._dataStream.asObservable();
  }

  public addFile(file: File): void {
    const newFileProgress: FileProgress = {
      file,

      progress: 0,
      aborted: false,
      uploaded: false,
    };
    this.fileProgressList.push(newFileProgress);
    this.abortSubjects.set(file, new Subject());
    this._dataStream.next(this.fileProgressList);
  }
  /* eslint-disable @typescript-eslint/typedef */

  public abortUpload(file: File): void {
    this.showInfoMessage('Aborting Document upload');
    const abortSubject = this.abortSubjects.get(file);
    if (abortSubject) {
      abortSubject.next(null);
      /* eslint-disable @typescript-eslint/no-explicit-any */
      abortSubject.complete();
      const index = this.fileProgressList.findIndex((fp) => fp.file === file);
      if (index > -1) {
        /* eslint-disable @typescript-eslint/typedef */
        this.fileProgressList[index].aborted = true;
        this._dataStream.next(this.fileProgressList);
        this.fileProgressList.splice(index, 1);
      }
    }
  }
  public markAsUploaded(file: File): void {
    const index = this.fileProgressList.findIndex((fp) => fp.file === file);
    if (index > -1) {
      this.fileProgressList[index].uploaded = true;
      this.fileProgressList.splice(index, 1);
      this._dataStream.next(this.fileProgressList);
    }
  }
  public setAbortSubjectForFile(file: File, abortSubject: Subject<any>): void {
    this.abortSubjects.set(file, abortSubject);
  }

  /* eslint-disable @typescript-eslint/typedef */
  public updateProgress(file: File, progress: number): void {
    const index = this.fileProgressList.findIndex((fp) => fp.file === file);
    if (index > -1) {
      this.fileProgressList[index].progress = progress;
      this._dataStream.next(this.fileProgressList);
    }
  }

  public uploadFile({
    /* eslint-disable @typescript-eslint/typedef */
    file,
    url,
    uploadFileName,
    documentFileName,
    documentScope,
    isItemFile = false,
    locationID,
    taskID,
    link,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    context, // for passing events
  }: FileUploadParams): Observable<any> {
    const req = new HttpRequest('PUT', url, file, {
      reportProgress: true,
    });

    const abortSubject = this.abortSubjects.get(file);
    if (abortSubject) {
      return this.http
        .request(req)
        .pipe(takeUntil(abortSubject))
        .pipe(
          map((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              const progress = Math.round((event.loaded / event.total) * 100);
              this.updateProgress(file, progress);
            } else if (event instanceof HttpResponse) {
              this.markAsUploaded(file);
              if (!isItemFile) {
                this.createDocument({
                  documentFileName,
                  uploadFileName,
                  documentScope,
                  locationID,
                  taskID,
                }).subscribe((res: any) => {
                  if (res.data) {
                    this.uploadedFiles.push(res.data);
                    this.uploadedFilesSubject.next({ context, ...res.data });
                    if (taskID && link == true) {
                      this.linkTaskDocument(
                        taskID,
                        /* eslint-disable @typescript-eslint/typedef */
                        res.data.documentID,
                      ).subscribe(() => {
                        this.taskService
                          .getTaskById(taskID)
                          .pipe(take(1))
                          .subscribe();
                      });
                    }
                  }
                  return res.data;
                });
                return;
              }
              const doc = { file, uploadedFileName: this.uploadedFileName };
              this.uploadedFiles.push(doc);
              this.uploadedFilesSubject.next(doc);
            }
          }),
        );
    }
    return of(null);
  }

  public readonly getReferences = (
    req: ApiRequestParams,
  ): Observable<ApiDataResponseArray<TableReference>> => {
    const params = req ?? defaultApiRequestParams;
    return this.http
      .get(`${this.apiUrl}legal-ref`, { params: createHttpParams(params) })
      .pipe(
        map((res: ApiResponse) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public readonly createReference = (
    payload: CreateLinkParams,
  ): Observable<ApiResponse> => {
    return this.http.post(`${this.apiUrl}legal-ref`, payload).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public readonly updateReference = (
    payload: EditLawParams,
  ): Observable<ApiResponse> => {
    return this.http
      .put(`${this.apiUrl}legal-ref/${payload.legalRefID}`, { ...payload })
      .pipe(
        map((res: ApiResponse) => {
          return res.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        /* eslint-disable @typescript-eslint/no-explicit-any */
      );
  };

  public readonly deleteReference = (
    RefId: number | undefined,
  ): Observable<ApiResponse> => {
    return this.http.delete(`${this.apiUrl}legal-ref/${RefId}`).pipe(
      map((res: ApiResponse) => {
        return res.data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public showMessage = (message: string, isError = false) => {
    if (isError) {
      this.notificationService.openErrorSnackBar(message);
      return;
    }
    this.notificationService.openSuccessSnackBar(message);
  };
  public showInfoMessage = (message: string) => {
    this.notificationService.handleError(message);
  };

  linkTaskDocument(taskID: number, documentID: number) {
    return this.http
      .post(`${this.apiUrl}task-document`, { taskID, documentID })
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  public getTags = (req: ApiRequestParams): Observable<ApiResponse> => {
    const params = req ?? defaultApiRequestParams;
    return this.http
      .get(`${this.apiUrl}tags`, { params: createHttpParams(params) })
      .pipe(
        map((res: ApiResponse) => {
          res.data = res
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  public deleteTag = (tagID: number): Observable<ApiResponse> => {
    return this.http.delete(`${this.apiUrl}tags/${tagID}`).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  public createTag = (tagName: string): Observable<ApiResponse> => {
    return this.http.post(`${this.apiUrl}tags`, { name: tagName }).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  public updateTag = (tagID: number, tagName: string): Observable<ApiResponse> => {
    return this.http.patch(`${this.apiUrl}tags/${tagID}`, { name: tagName }).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }
}
