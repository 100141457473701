import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Category, CategoryPreview, CategoryPreviewApiResponse, CreateCategoryRequestParams, CreateCategoryResponse } from 'src/models/category.model';

@Injectable()
export class CategoryService {
  public apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public readonly createCategory = (
    body: CreateCategoryRequestParams,
  ): Observable<CreateCategoryResponse> => {
    return this.http.post(`${this.apiUrl}topics`, body).pipe(
      map((res: CreateCategoryResponse) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public readonly getCategories = (
  ): Observable<CategoryPreview[]> => {
    return this.http
      .get(`${this.apiUrl}topics`)
      .pipe(
        map((res: CategoryPreview[]) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public readonly updateCategories = (
    categoryArray: CategoryPreviewApiResponse[],
  ): Observable<any> => {
    return this.http
      .post(`${this.apiUrl}topics/bulk-update`, { categoryArray })
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public readonly deleteCategory = (categoryID: number): Observable<any> => {
    return this.http.delete(`${this.apiUrl}topics/${categoryID}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  /* eslint-disable @typescript-eslint/typedef */

  public readonly searchCategories = (
    categories: Category[],
    searchTerm: string,
    expand: boolean = true,
  ): Category[] => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filteredCategories = categories.filter((cat) => {
      const normalizedTitle = cat.topicTitle.toLowerCase();
      return normalizedTitle.includes(lowerCaseSearchTerm);
    });

    if (searchTerm.length === 0) {
      return filteredCategories;
      /* eslint-disable @typescript-eslint/typedef */
    }

    const outputTree: Category[] = [];

    filteredCategories.forEach((category) => {
      let parent = categories.find(
        (cat) => cat.topicID === category.topicParentID,
      );

      const exists = outputTree.find((cat) => cat.topicID === category.topicID);
      while (parent) {
        const parentExists = outputTree.find(
          (cat) => cat.topicID === parent?.topicID,
        );
        if (!parentExists) {
          outputTree.push(parent);
        }
        if (expand) {
          parent.isExpanded = true;
        }
        parent = categories.find(
          (cat) => cat.topicID === parent?.topicParentID,
        );
      }
      if (!exists) {
        outputTree.push(category);
      }
    });

    return outputTree;
  };
}
