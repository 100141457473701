import { Injectable, Component, Inject, NgZone } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { NgIconComponent } from '@ng-icons/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-snackbar',
  templateUrl: '../app/shared/templates/snackbar/snackbar.template.html',
  standalone: true,
  imports: [MatIconModule, NgIconComponent, NgIf],
})
export class MessageArchivedComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { message: string; type: string },
    private snackBarRef: MatSnackBar,
  ) {}

  closeSnackBar() {
    this.snackBarRef.dismiss();
  }
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private _snackBar: MatSnackBar,
    private ngZone: NgZone,
  ) {}
  openSuccessSnackBar(
    message: string,
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    durationInSeconds = 5,
  ) {
    this._snackBar.openFromComponent(MessageArchivedComponent, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000,
      panelClass: ['success-snackbar'],
      data: { message, type: 'success' },
    });
  }

  openErrorSnackBar(
    message: string,
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    durationInSeconds = 5,
  ) {
    this._snackBar.openFromComponent(MessageArchivedComponent, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000,
      panelClass: ['danger-snackbar'],
      data: { message, type: 'warning' },
    });
  }
  openInfoSnackBar(
    message: string,
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    durationInSeconds = 5,
  ) {
    this._snackBar.openFromComponent(MessageArchivedComponent, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000,
      panelClass: ['info-snackbar'],
      data: { message, type: 'info' },
    });
  }
  handleError(
    message: string,
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    durationInSeconds = 5,
  ) {
    this.ngZone.run(() => {
      setTimeout(() => {
        this._snackBar.openFromComponent(MessageArchivedComponent, {
          horizontalPosition: horizontalPosition,
          verticalPosition: verticalPosition,
          duration: durationInSeconds * 1000,
          panelClass: ['info-snackbar'],
          data: { message, type: 'info' },
        });
      }, 0);
    });
  }
}
