import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CurrentUserResponse } from '@root/models/user.model';
import { LocalStoreService } from '@root/services/local-store.service';
import { PermissionsService } from '@root/services/permissions.service';
import { UserService } from '@root/services/user-service/user.service';
import { GeneralStore } from '@root/store/general.store';
import { Observable } from 'rxjs';

export const PermissionsGuard: CanActivateFn = (route, _state) => {
  /* eslint-disable @typescript-eslint/typedef */
  const generalStore = inject(GeneralStore);
  const permissionsService = inject(PermissionsService);
  const authService = inject(LocalStoreService);
  const router = inject(Router);
  const userService = inject(UserService);
  return new Observable<boolean>((subscriber) => {
    const atLeastOneGlobal: number[] = route.data['atLeastOneGlobal'];
    const locationId: number = Number(route.params['locationId']);
    const atLeastOneLocal: number[] = route.data['atLeastOneLocal'];
    const checkTaskboardPermissions: boolean = route.data['checkTaskboardPermissions'];

    if (_state.url === '/') {
      const token: string = authService.getItem('JWT_TOKEN');
      const refreshToken: string = authService.getItem('JWT_REFRESH_TOKEN');
      router.navigate(['/login'], { queryParams: { access_token: token, refresh_token: refreshToken } });
    } else if (generalStore.isLoaded()) {
      subscriber.next(permissionsService.checkRoutePermissions(atLeastOneGlobal, locationId, atLeastOneLocal, checkTaskboardPermissions));
    } else {
      userService.getCurrentUser().subscribe((res: CurrentUserResponse) => {
        generalStore.setCurrentUser(res.data);
        subscriber.next(permissionsService.checkRoutePermissions(atLeastOneGlobal, locationId, atLeastOneLocal, checkTaskboardPermissions));
      });
    }
  });
};
