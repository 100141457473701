import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  ApiDataResponseArray,
  ApiRequestParams,
  ApiResponse,
  ApiResponseArray,
  defaultApiRequestParams,
  EventTaskInfo,
  ScoreTaskResonse,
  TaskboardInfo,
} from 'src/models/global-interfaces';
import { createHttpParams, flattenResponse } from 'src/services/utilities';
import {
  CreateTaskRequest,
  TaskRequest,
  TaskResponse,
  TaskDeploymentPayload,
  TaskInfoResponse,
  TaskPreview,
  taskTableApiRequestParams,
  EditTask,
  WorkOnTask,
  TaskSettings,
  TaskUpdatePayload,
  NewTaskTemplates,
  DeployLocation,
  DuplicateTaskRequest,
  RecallDeploymentPayload,
  TaskSetTask,
  UnlinkTaskPayload,
  IncompleteTask,
  DelayTaskRequest,
  ClosedTaskRequest,
  ClosedTaskResponse,
  eventResponse,
  EventTaskInfoResponse,
  TaskReminderRequest,
  ClosedTask,
  SpawnTaskPayload,
  AssignableTaskRequest,
  AssignableTask,
  TaskUpdateTags,
} from '@models/task.model';
import { format } from 'date-fns';
import { TaskItem } from '@root/models/task-item.model';
import { tapResponse } from '@ngrx/operators';
import { TaskReoccurs } from '@models/task.model';
import { DocumentsResponse } from '@root/models/document.model';
import { DefaultColorValue } from '@root/models/global-enum';

@Injectable()
export class TaskService {
  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) {}

  public getTasks(
    req: ApiRequestParams,
  ): Observable<ApiDataResponseArray<TaskPreview>> {
    /* eslint-disable @typescript-eslint/typedef */
    const params = req ?? taskTableApiRequestParams;
    return this.http
      .get<TaskPreview[]>(`${this.apiUrl}tasks`, {
        params: createHttpParams(params),
      })
      .pipe(
        map((res: TaskPreview[]) => {
          return { data: res } as ApiDataResponseArray<TaskPreview>;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public getAssignableTasks(req: AssignableTaskRequest): Observable<AssignableTask[]> {
    /* eslint-disable @typescript-eslint/typedef */
    const params = req ?? taskTableApiRequestParams;
    return this.http
      .get<AssignableTask[]>(`${this.apiUrl}tasks/assignable-tasks`, {
        params: createHttpParams(params),
      })
      .pipe(
        map((res: AssignableTask[]) => {
          return res;
        }),
        /* eslint-disable @typescript-eslint/no-explicit-any */
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public getTaskById(taskId: number): Observable<ApiResponse> {
    return this.http.get(`${this.apiUrl}tasks/${taskId}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public getTaskForEdit (
    req: TaskRequest,
  ): Observable<TaskResponse<EditTask>> {
    return this.http
      .get(`${this.apiUrl}tasks/${req.id}/${req.scope}/editTask`)
      .pipe(
        map((res: TaskResponse<EditTask>) => {
          return flattenResponse(res, true);
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public getTaskForWork (
    req: TaskRequest,
  ): Observable<TaskResponse<WorkOnTask>> {
    return this.http
      .get(`${this.apiUrl}tasks/${req.id}/${req.scope}/workOnTask`)
      .pipe(
        map((res: TaskResponse<WorkOnTask>) => {
          return flattenResponse(res, true);
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public getTaskInfo (
    req: TaskRequest,
  ): Observable<TaskInfoResponse> {
    return this.http
      .get(`${this.apiUrl}tasks/${req.id}/${req.scope}/taskInfo`)
      .pipe(
        map((res: TaskInfoResponse) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public getTaskSettings(
    req: TaskRequest,
  ): Observable<TaskResponse<TaskSettings>> {
    return this.http
      .get(`${this.apiUrl}tasks/${req.id}/${req.scope}/taskSettings`)
      .pipe(
        map((res: TaskResponse<TaskSettings>) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public createTask(
    req?: CreateTaskRequest,
  ): Observable<ApiResponse> {
    return this.http
      .post(`${this.apiUrl}tasks`, { ...req, name: 'New Task Template' })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public updateTask(
    task?: TaskUpdatePayload,
  ): Observable<ApiResponse> {
    return this.http.put(`${this.apiUrl}tasks/${task?.taskID}`, task).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public updateTaskTags(
    task?: TaskUpdateTags,
  ): Observable<ApiResponse> {
    return this.http.put(`${this.apiUrl}tasks/${task?.taskID}`, task).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  deleteTask(taskID: number): Observable<NewTaskTemplates> {
    return this.http.delete(`${this.apiUrl}tasks/${taskID}`).pipe(
      map((res: any) => {
        return res.body;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  taskDeployment(payload: TaskDeploymentPayload): Observable<DeployLocation> {
    return this.http
      .post(`${this.apiUrl}deploy-task`, payload, {
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res.body;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  duplicateTask(payload: DuplicateTaskRequest): Observable<NewTaskTemplates> {
    return this.http
      .post(`${this.apiUrl}tasks/copy`, payload, {
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res.body;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  duplicateSchedule(taskReoccurID: number): Observable<TaskReoccurs> {
    return this.http
      .patch(`${this.apiUrl}task-reoccur/duplicate/${taskReoccurID}`, {
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  resetTask(taskID: number): Observable<ApiResponse> {
    return this.http.patch(`${this.apiUrl}tasks/reset/${taskID}`, {}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  recallTaskDeployment(payload: RecallDeploymentPayload): Observable<TaskItem> {
    return this.http
      .post(`${this.apiUrl}recall-deployed-task`, payload, {
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res.body;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  public unlinkTaskDocument(
    documentID: number,
  ): Observable<DocumentsResponse> {
    return this.http.delete(`${this.apiUrl}task-document/${documentID}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public updateTaskReoccurs(
    taskReoccurs: TaskReoccurs,
  ): Observable<ApiResponse> {
    if (taskReoccurs.startAt) {
      taskReoccurs.startAt! = format(
        new Date(taskReoccurs.startAt!),
        'yyyy-MM-dd HH:mm:ss',
      );
    }
    return this.http
      .put(`${this.apiUrl}task-reoccur/${taskReoccurs.reoccurID}`, taskReoccurs)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public unlinkTask(
    task: UnlinkTaskPayload,
  ): Observable<ApiResponse> {
    return this.http
      .put(`${this.apiUrl}unlink-task-from-task-set/${task.taskID}`, task)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public updateTaskOrder(
    tasks: TaskSetTask[] | undefined,
  ): Observable<any> {
    return this.http.put(`${this.apiUrl}tasks/task-set-order`, { tasks }).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public spawnTask(
    task?: SpawnTaskPayload,
  ): Observable<ApiResponse> {
    return this.http.post(`${this.apiUrl}tasks/spawn-task`, task).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public getIncompleteTasks(
    req: ApiRequestParams,
    locationId: number,
  ): Observable<ApiResponseArray<IncompleteTask>> {
    const params = req ?? defaultApiRequestParams;
    return this.http
      .get(`${this.apiUrl}incomplete-tasks/${locationId}`, {
        params: createHttpParams(params),
      })
      .pipe(
        tapResponse({
          next: (res: ApiResponseArray<IncompleteTask>) => {
            return res.data;
          },
          error: (_error) => {
            // console.error(error);
          },
        }),
      );
  };

  public getEventTasks(
    params: ApiRequestParams,
  ): Observable<eventResponse> {
    return this.http.post(`${this.apiUrl}event-info`, params).pipe(
      map((res: eventResponse) => {
        res.data.data = res.data;
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public getEventTaskSpawnInfo(
    params: EventTaskInfo,
  ): Observable<EventTaskInfoResponse> {
    return this.http
      .post(`${this.apiUrl}tasks/event-task-spawn-info`, params)
      .pipe(
        map((res: EventTaskInfoResponse) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public getTaskboardInfo(
    req: ApiRequestParams,
  ): Observable<TaskboardInfo> {
    return this.http
      .get(`${this.apiUrl}task-board/?locationID=${req.locationID}`)
      .pipe(
        map((res: TaskboardInfo) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  public delayTask(
    req?: DelayTaskRequest,
  ): Observable<ApiResponse> {
    return this.http.post(`${this.apiUrl}task-delays`, req).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public failTask(): Observable<ApiResponse> {
    return this.http.post(`${this.apiUrl}fail-task`, {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  public getClosedTasks(
    req: ClosedTaskRequest,
  ): Observable<ApiDataResponseArray<ClosedTask>> {
  
    let url = `${this.apiUrl}closed-tasks?locationID=${req.locationID}&completedStartDate=${req.completedStartDate}&completedEndDate=${req.completedEndDate}`;
  
    if (req.dueStartDate) {
      url += `&dueStartDate=${req.dueStartDate}`;
    }
  
    if (req.dueEndDate) {
      url += `&dueEndDate=${req.dueEndDate}`;
    }
  
    return this.http
      .get<ClosedTaskResponse>(url)
      .pipe(
        map((res: ClosedTaskResponse) => {
          return {
            data: res.closedTasks,
          };
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public sendReminder(
    req?: TaskReminderRequest,
  ): Observable<ApiResponse> {
    return this.http.post(`${this.apiUrl}tasks/send-reminder`, req).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public completeTask(taskID: number): Observable<ApiResponse> {
    return this.http.post(`${this.apiUrl}complete-task`, { taskID }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public getTaskScore(taskId: number): Observable<ScoreTaskResonse> {
    return this.http.get(`${this.apiUrl}tasks/${taskId}/scorecard`).pipe(
      map((res: ScoreTaskResonse) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public setTaskScore(taskId: number, score: number, task_observation: string, task_recommendation: string): Observable<ApiResponse> {
    const params = {
      task_id: taskId,
      score: score,
      task_observation: task_observation,
      task_recommendation: task_recommendation
    }
    return this.http.post(`${this.apiUrl}score-task`, params).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }
  public updateTaskScore(taskId: number, score: number, task_observation: string, task_recommendation: string, scoreId: number): Observable<ApiResponse> {
    const params = {
      score: score,
      task_observation: task_observation,
      task_recommendation: task_recommendation,
      id: scoreId
    }
    return this.http.patch(`${this.apiUrl}score-task/${scoreId}`, params).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  public deleteTaskItemFromArray(taskItems: TaskItem[], itemId: number): void {
    let indexToDelete: number = -1;
    taskItems.forEach((item, index: number) => {
      if (indexToDelete === -1) {
        if (item.itemID === itemId) {
          indexToDelete = index;
        }
        else if (item.children) {
          this.deleteTaskItemFromArray(item.children, itemId);
        }
      }
    });
    
    if (indexToDelete !== -1) {
      taskItems.splice(indexToDelete, 1);
    }
  }

  public calcDaysPastDue(
    date: string | null,
    color: number,
  ): { color: string | null; status: string | null } {
    const output: { color: string | null; status: string | null } = {
      /* eslint-disable @typescript-eslint/typedef */
      color: DefaultColorValue[color] || 'none',
      status: null as string | null,
    };
    if (date) {
      const dueDate = new Date(date);
      const today = new Date();
      const diffTime = dueDate.getTime() - today.getTime();
      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
      const diffHours = Math.round(diffTime / (1000 * 60 * 60));
      const diffMinutes = diffTime / (1000 * 60);
      const unit = Math.abs(diffDays) > 1 ? 'Days' : 'Day';

      // Calculate due time to nearest day
      if (Math.abs(diffDays) < 1) {
        output.status = this.calcHoursPastDue(diffHours, diffMinutes);
        return output;

        /* eslint-disable @typescript-eslint/typedef */
      }

      if (diffDays < 0) {
        output.status = `${Math.ceil(Math.abs(diffDays))} ${unit} Overdue`;
      }
      if (diffDays > 0) {
        output.status = `${Math.ceil(diffDays)} ${unit} Left`;
      }
    }

    return output;
  }

  public getDaysPastDueHtml(dueDateStatus: { color: string | null; status: string | null }, isDelayed: boolean): string {
    if (isDelayed) {
      return `
        <div class="due-display">
          <span class="due-status ${dueDateStatus.color}">${dueDateStatus.status} (delayed)</span>
        </div>
      `;
    } else {
      return `
        <div class="due-display">
          <span class="due-status ${dueDateStatus.color}">${dueDateStatus.status}</span>
        </div>
      `;
    }
  }

  calcHoursPastDue(diffHours: number, diffMinutes: number): string {
    let output = '';
    const unit = Math.abs(diffHours) > 1 ? 'Hours' : 'Hour';

    // Calculate due time to nearest hour
    if (Math.abs(diffHours) < 1) {
      output = this.calcMinutesPastDue(diffMinutes);
      return output;
    }
    if (diffHours < 0) {
      output = `${Math.round(Math.abs(diffHours))} ${unit} Overdue`;
    }
    if (diffHours > 0) {
      output = `${Math.round(diffHours)} ${unit} Left`;
    }

    return output;
  }

  calcMinutesPastDue(diffMinutes: number): string {
    let output = '';
    const unit = Math.abs(diffMinutes) > 2 ? 'Minutes' : 'Minute';

    // Calculate due time to nearest minute (rounding down)
    if (Math.abs(Math.floor(diffMinutes)) === 0) {
      output = 'Due Now';
      return output;
    }
    if (Math.floor(diffMinutes) < 1) {
      output = `${Math.floor(Math.abs(diffMinutes))} ${unit} Overdue`;
    }
    if (Math.floor(diffMinutes) >= 1) {
      output = `${Math.floor(diffMinutes)} ${unit} Left`;
    }

    return output;
  }
}
