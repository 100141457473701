import { Injectable } from '@angular/core';
import { LocalStoreService } from '../local-store.service';
import { HttpClient } from '@angular/common/http';
import { environment } from "@root/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class JwtAuthService {
  JWT_TOKEN = 'JWT_TOKEN';
  JWT_REFRESH_TOKEN = 'JWT_REFRESH_TOKEN';
  USER_TYPE = 'USER_TYPE';
  public apiUrl = environment.apiUrl;

  constructor(private ls: LocalStoreService, private http: HttpClient) {}

  isLoggedin() {
    return !!this.ls.getItem(this.JWT_TOKEN);
  }

  logout() {
    this.http.get(`${this.apiUrl}auth/logout`, {}).subscribe(() => {
      this.ls.clear();
    });
  }

  setUserToken(token: string) {
    this.ls.setItem(this.JWT_TOKEN, token);
  }
  setRefreshToken(token: string) {
    this.ls.setItem(this.JWT_REFRESH_TOKEN, token);
  }
}
