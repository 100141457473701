import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
  standalone: true,
  imports: [CommonModule, NgIconsModule],
})
export class InputErrorComponent {
  @Input() type: string = 'error';
  @Input() errorMessage: string = '';
  @Input() condition: boolean = true;
}
