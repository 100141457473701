@if (errorMessage && condition) {
  <div
    [ngClass]="{
      'error-container': type === 'error',
      'success-container': type === 'success'
    }"
  >
    @if (type === 'success') {
      <ng-icon name="heroCheckCircle"></ng-icon>
    } @else {
      <ng-icon name="heroXCircle"></ng-icon>
    }

    <span
      [ngClass]="{ error: type === 'error', success: type === 'success' }"
      >{{ errorMessage }}</span
    >
  </div>
}
