import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogModule,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgIconsModule } from '@ng-icons/core';
import { StatusIcons } from '@root/models/global-interfaces';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    NgIconsModule,
    DialogHeaderComponent,
    MatCheckbox,
    FormsModule
  ],
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  @ViewChild('dialogHeader', { static: true })
  dialogHeader!: TemplateRef<DialogHeaderComponent>;
  icons = StatusIcons;
  checked = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogComponent>,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  close(result?: any) {
    if(this.data.checkBox && result) {
      this.dialogRef.close({openTasksOption: this.checked});
    }
    else {
      this.dialogRef.close(result);
    }
  }

  check() {
  this.checked = !this.checked;  
  }
}
